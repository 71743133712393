import { format } from "date-fns";

import { CodeSnippet } from "@models/shared/code-snippet";
import { VzCrashData } from "@models/shared/crash-data";
import { StoreService } from "@services/store.service";
import { environment } from '@env/environment';
import { getDeviceInfo } from "./device";
import { sortDatesBy } from "./sort";

export function getSnippetsForTicket(store: StoreService, cd?: VzCrashData): CodeSnippet[] {
    const logs = store.appLog.getItems().filter(item => !!item);
    const rmqLogs = store.getState('stomp').items.filter(item => !!item).sort(sortDatesBy('dt'));
    const env: any = { ...environment };
    delete env.firebaseConfig;
    const res: CodeSnippet[] = cd ? [new CodeSnippet({ title: 'Stack trace', syntax: 'plaintext', mimeType: 'text/plain', text: cd.stack })] : [];
    res.push(
        new CodeSnippet({
            title: 'Журнал работы приложения',
            syntax: 'log',
            mimeType: 'text/plain',
            text: logs.map(l =>
                format(l.dt * 1000, 'dd.MM.y HH:mm:ss.SSS')
                + (l.severity == 'warn' ? ' W ' : l.severity == 'error' ? ' E ' : '   ')
                + `${l.tag} `
                + l.items.join(' ')
            ).join('\n')
        }),
        new CodeSnippet({
            title: 'Environment',
            syntax: 'json',
            mimeType: 'application/json',
            text: JSON.stringify(env, undefined, 4)
        }),
        new CodeSnippet({
            title: 'RMQ Logs',
            syntax: 'log',
            mimeType: 'application/json',
            text: rmqLogs.map(l =>
                format(l.dt, 'dd.MM.y HH:mm:ss.SSS')
                + (l.rc ? ' C ' : '   ')
                + `${l.o}_${l.t} `
                + `${l.e}.${l.rk} `
                + 'ids: [' + (l.ids || []).join(',') + ']'
                + (l.data ? ' data: ' + JSON.stringify(l.data) : '')
            ).join('\n') + ' '
        }),
        new CodeSnippet({
            title: 'Device',
            syntax: 'json',
            mimeType: 'application/json',
            text: JSON.stringify(getDeviceInfo(), undefined, 4)
        }),
    );
    try {
        const err = localStorage?.getItem('_vizorro_last_token_update_permanent_error');
        if (err) {
            res.push(new CodeSnippet({
                title: 'last_token_update_permanent_error',
                syntax: 'json',
                mimeType: 'application/json',
                text: err
            }));
        }
    } catch(_) {}
    return res;
}